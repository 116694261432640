import { useState } from "react"
import axios from "../api/axios";
//import { useNavigate } from "react-router-dom";

const MerchantSignUp = () => {
    const [id, setId] = useState(0);
    const [uuid, setUuid] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [myshopify_domain, setDomain] = useState('');
    const [phone, setPhone] = useState('');
    const [address1, setAddress1] = useState('');
    const [address2, setAddress2] = useState('');
  //const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      'name':name,
      'email':email,
      'password':'123456',
      'role':'merchant'
    };

    try{
        await axios.post('/api/register', data).then(response=>{
            const data = {
                'name':name,
                'email':email,
                'myshopify_domain':myshopify_domain,
                'phone':phone,
                'address1':address1,
                'address2':address2,
                'user_id':response.data.id,
                'uuid':uuid
            };
            axios.post('/api/merchants/signup', data).then(result=>{
                alert(result.data.message);
                setName('');
                setEmail('');
                setDomain('');
                setPhone('');
                setAddress1('');
                setAddress2('');
                setId('');
                setUuid('');
              }, (err)=>{
                console.log(err);
                alert('Please supply all fields.');
              });
          }, (err)=>{
            console.log(err);
            alert('Please supply all fields.');
          });
    } catch(e){
      console.log(e);
    }

  }

  return (
    <div className="container-xxl align-items-center">
    <div className="authentication-wrapper container-p-y sign-up-center">
        <div className="authentication-inner">
        <div className="card">
            <div className="card-body">
            <div className="text-center">
                <img className="app-brand-logo-app signup-logo-img" alt="" src="../gyfhint-logo.png"></img>
            </div>
            <h5 className="mb-2 text-center">Create Merchant Profile</h5><br/>
            <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
                <input type="hidden" id='uuid' name='uuid' defaultValue={uuid}></input>
                <input type="hidden" id='id' name='id' defaultValue={id}></input>
                <div className="col-sm-12">
                    <label className="form-label">Merchant Name</label>
                    <div className="input-group input-group-merge">
                    <span id="name" className="input-group-text"><i className="bx bx-detail"></i></span>
                    <input type="text" id="name" required className="form-control dt-full-name" name="name" defaultValue={name} onChange={(e)=>setName(e.target.value)}/>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">E-mail Address</label>
                    <div className="input-group input-group-merge">
                    <span id="email" className="input-group-text"><i className="bx bxs-envelope"></i></span>
                    <input type="text" className="form-control" required name="email" value={email} id="email" onChange={(e)=>setEmail(e.target.value)}>
                    </input>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Domain</label>
                    <div className="input-group input-group-merge">
                    <span id="myshopify_domain" className="input-group-text"><i className="bx bx-globe"></i></span>
                    <input type="text" className="form-control" required name="myshopify_domain" value={myshopify_domain} id="myshopify_domain" onChange={(e)=>setDomain(e.target.value)}>
                    </input>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Phone</label>
                    <div className="input-group input-group-merge">
                    <span id="phone" className="input-group-text"><i className="bx bxs-phone"></i></span>
                    <input type="text" className="form-control" required name="phone" value={phone} id="phone" onChange={(e)=>setPhone(e.target.value)}>
                    </input>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Addres 1</label>
                    <div className="input-group input-group-merge">
                    <span id="address1" className="input-group-text"><i className="bx bxs-map"></i></span>
                    <input type="text" className="form-control" required name="address1" value={address1} id="phone" onChange={(e)=>setAddress1(e.target.value)}>
                    </input>
                    </div>
                </div>
                <div className="col-sm-12">
                    <label className="form-label">Address 2</label>
                    <div className="input-group input-group-merge">
                    <span id="address2" className="input-group-text"><i className="bx bxs-map"></i></span>
                    <input type="text" className="form-control" required name="address2" value={address2} id="address2" onChange={(e)=>setAddress2(e.target.value)}>
                    </input>
                    </div>
                </div>
                <div className="col-sm-12">
                    <br />
                <input type="checkbox"></input> 
                &nbsp; I agree to receive messages from GyftHint at the phone number provided above notifying me of upcoming gifting events for my friends and family. I understand data rates may apply. I may reply STOP to opt out at any time.
                </div>
                <div className="col-sm-12">
                    <br />
                    <button type="submit" className="btn btn-primary data-submit me-sm-3 me-1" onClick={handleSubmit}>Send my Code</button>
                </div>
                </form>
            </div>
        </div>
        </div>
    </div>
    </div>
  )
}

export default MerchantSignUp