import React from "react"

const Footer = () => {
  return (
    <footer className="content-footer footer bg-footer-theme">
        <div className="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
            <div className="mb-2 mb-md-0">
                Copyright © &nbsp;
                <script>
                    document.write(new Date().getFullYear());
                </script>
                <a href="/" className="footer-link fw-medium">GyftHint, Inc.</a>
            </div>
        </div>
    </footer>
  )
}

export default Footer