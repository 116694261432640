import React from "react";
import Chart from "../components/Chart";

const Analytics = () => {
  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
            <div className="row">
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                        <div className="icon">👥</div>
                        <div className="details">
                            <h2>Returning Users</h2>
                            <p>2,653 <span className="increase">+2.74%</span></p>
                        </div>
                        </div>
                    </div>
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                        <div className="icon">📉</div>
                            <div className="details">
                                <h2>Bounce Rate</h2>
                                <p>23.64% <span className="increase">+0.98%</span></p>
                            </div>
                        </div>
                    </div>
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                        <div className="icon">✅</div>
                            <div className="details">
                                <h2>Conversion Rate</h2>
                                <p>78% <span className="increase">+3.89%</span></p>
                            </div>
                    </div>
                </div>
                <div className="col-4 col-lg-3">
                <div className="kpi">
                    <div className="icon"><i className="menu-icon-page tf-icons bx bx-money"></i></div>
                        <div className="details">
                            <h2>Session Duration</h2>
                            <p>00:25:30 <span className="increase">+1.45%</span></p>
                        </div>
                    </div>
                </div>
            </div>          
        </div>
        <div className="row">
            <div className="col-12 col-lg-6">
                <div className="top-pages col-dash-card">
                    <h2>Top Pages</h2>
                    <table>
                    <thead>
                        <tr>
                        <th>Page Path</th>
                        <th>Page Views</th>
                        <th>Sessions</th>
                        <th>New Users</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>/home</td>
                        <td>10,653</td>
                        <td>7,456</td>
                        <td>2,456</td>
                        </tr>
                        <tr>
                        <td>/shop</td>
                        <td>10,273</td>
                        <td>7,364</td>
                        <td>2,164</td>
                        </tr>
                        <tr>
                        <td>/catalog</td>
                        <td>9,367</td>
                        <td>6,853</td>
                        <td>1,953</td>
                        </tr>
                        <tr>
                        <td>/about</td>
                        <td>9,157</td>
                        <td>6,273</td>
                        <td>1,573</td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
            <div className="col-12 col-lg-6">
                <div className="rates-chart col-dash-card">
                <h2>Bounce Rate vs. Exit Rate</h2>
                <Chart />
            </div>
            </div>
        </div>    
    </div>
  )
}

export default Analytics