import React, {useEffect } from "react";
import axios from "../api/axios";
//import { useNavigate } from "react-router-dom";

const Navbar = () => {
  //const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  const stores = JSON.parse(localStorage.getItem('stores'));
  const store = localStorage.getItem('currentActiveStore');
  const store_id = localStorage.getItem('currentActiveStoreId');
  const license = localStorage.getItem('currentActiveStoreLicense');

  if(token==='' || token === null || token === undefined){
    window.location = '/login';
  }
  const user_id = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  const handleLogout = async(event) => {
      event.preventDefault();
      try{
        await axios.get('/api/logout', config).then(result =>{
          localStorage.setItem('accessToken', '');
          localStorage.setItem('menuAccess', []);
          localStorage.setItem('profileRole', '');
          window.location.href = '/login';
        }, (err)=>{
          alert(err);
        });
      } catch (e){
        console.log(e);
        alert(e);
      }
  }
  
  useEffect(() => {
    fetchProfile();
  }, [])

  const fetchProfile = async () => {
    try{
      await axios.get('/api/profile/' + user_id, config).then(result =>{
        localStorage.setItem('profileName', result.data.data[0].name);
        localStorage.setItem('profileEmail', result.data.data[0].email);
        localStorage.setItem('profileRole', result.data.data[0].role_name);
      }, (err)=>{
        localStorage.setItem('accessToken', '');
        localStorage.setItem('menuAccess', []);
        localStorage.setItem('profileRole', '');
        window.location.href = '/login';
      });
    } catch(err){
      console.log(err);
      localStorage.setItem('accessToken', '');
      localStorage.setItem('menuAccess', []);
      localStorage.setItem('profileRole', '');
      window.location.href = '/login';
    }
  }
  const handleChangeActiveStore = (id, store_name, store_logo, label) => {
    localStorage.setItem('currentActiveStoreId', id);
    localStorage.setItem('currentActiveStore', store_name);
    localStorage.setItem('currentActiveStoreLogo', store_logo);
    localStorage.setItem('currentActiveStoreLicense', label);
    window.location.reload();
  }
  return (
        <nav className="layout-navbar container-xxl navbar navbar-expand-xl navbar-detached align-items-center bg-navbar-theme" id="layout-navbar">
            <div className="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
              <a className="nav-item nav-link px-0 me-xl-4" href="">
                <i className="bx bx-menu bx-sm"></i>
              </a>
            </div>

            <div className="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
              <div className="navbar-nav align-items-center">
                <div className="nav-item d-flex align-items-center d-none">
                  <i className="bx bx-search fs-4 lh-0"></i>
                  <input
                    type="text"
                    className="form-control border-0 shadow-none ps-1 ps-sm-2"
                    placeholder="Search..."
                    aria-label="Search..." />
                </div>
              </div>
              <ul className="navbar-nav flex-row align-items-center ms-auto">
                {localStorage.getItem('profileRole').toLowerCase() == 'merchant' && 
                  <span><a href="mailto:sales@gyfthint.com" target="_blank" className="btn btn-warning btn-buy-now">Upgrade or Add Licenses</a></span>
                }
                {localStorage.getItem('profileRole').toLowerCase() == 'merchant' &&
                  <li className="nav-item navbar-dropdow">
                    {stores.length > 1 ?
                    <a className="nav-link dropdown-toggle hide-arrow" href="" data-bs-toggle="dropdown">
                      <div className="">
                      <span className="bx bxs-store-alt"></span> {store} <span className="bx bx-chevron-down"></span>
                      </div>
                    </a>:
                    <a className="nav-link dropdown-toggle hide-arrow" href="">
                      <div className="">  
                      <span className="bx bxs-store-alt"></span> {store}
                      </div>
                    </a>
                    }
                    <ul className="dropdown-menu dropdown-menu-end">
                      {(stores).map(store=>(
                        <li className="" key={store.id}>
                            {store_id !== store.uuid &&
                              <a className="dropdown-item" href="#" onClick={(e)=>handleChangeActiveStore(store.uuid, store.store_name, store.store_logo, store.label)}>
                                <i className="bx bx-store-alt me-2"></i>
                                <span className="align-middle">{store.store_name}</span>
                              </a>
                            }
                      </li>
                      ))}
                    </ul>
                  </li>
                }
                {localStorage.getItem('profileRole').toLowerCase() == 'merchant' && 
                  <span className="badge bg-label-primary me-1">{license}</span>
                }
                <li className="nav-item navbar-dropdown dropdown-user dropdown">
                  <a className="nav-link dropdown-toggle hide-arrow" href="" data-bs-toggle="dropdown">
                    <div className="avatar avatar-online user-initials">
                      {localStorage.getItem('profileName')!=null&&localStorage.getItem('profileName').charAt(0).toUpperCase()}
                    </div>
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <a className="dropdown-item" href="#">
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                          <div className="avatar avatar-online user-initials">
                            {localStorage.getItem('profileName')!=null&&localStorage.getItem('profileName').charAt(0).toUpperCase()}
                          </div>
                          </div>
                          <div className="flex-grow-1">
                            <span className="fw-medium d-block">{localStorage.getItem('profileName')}</span>
                            <small className="text-muted">{localStorage.getItem('profileRole').toUpperCase()}</small>
                          </div>
                        </div>
                      </a>
                    </li>
                    <li className="d-none">
                      <div className="dropdown-divider"></div>
                    </li> 
                    <li>
                      <a className="dropdown-item" href="/profile-settings">
                        <i className="bx bx-user me-2"></i>
                        <span className="align-middle">My Profile</span>
                      </a>
                    </li>
                    <li className="d-none">
                      <a className="dropdown-item" href="#">
                        <i className="bx bx-cog me-2"></i>
                        <span className="align-middle">Settings</span>
                      </a>
                    </li>
                    {localStorage.getItem('profileRole').toLowerCase() == 'merchant' &&
                        <li className="d-none">
                          <a className="dropdown-item" href="#">
                            <span className="d-flex align-items-center align-middle">
                              <i className="flex-shrink-0 bx bx-credit-card me-2"></i>
                              <span className="flex-grow-1 align-middle ms-1">Billing</span>
                              <span className="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">4</span>
                            </span>
                          </a>
                        </li>
                      }
                    <li>
                      <div className="dropdown-divider"></div>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={handleLogout}>
                        <i className="bx bx-power-off me-2"></i>
                        <span className="align-middle">Log Out</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </nav>
  )
}

export default Navbar