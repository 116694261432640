import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const Customers = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [table, setTable] = useState('');

  /* setting of forms */
  const [customers, setCustomers] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');

  const [id, setId] = useState(0);
  const [uuid, setUuid] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  let store_id = currentActiveStoreId == '' || currentActiveStoreId == undefined ? 1: currentActiveStoreId;
  const [storeId, setStoreId] = useState(store_id);

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here*/

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        alert(err.response.data.message);
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    setIsLoading(true);
    const url = '/api/customers/listbyorderapi/' + storeId;
    try{
      await axios.get(url, config ).then(result => {
        console.log(result.data.data);
        setTable(result.data.data);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const fetchData = async(id) => {
    setModal('show');
    try{
      await axios.get('/api/customers/' + id, config ).then(result => {
        console.log(result);
        const data = result.data.data;
        setFirstName(data.first_name);
        setLastName(data.last_name);
        setBirthDate(data.birthdate);
        setEmail(data.email);
        setPhone(data.phone);
        setId(data.id);
        setUuid(data.uuid);
      })
    }catch(e){
      console.log(e);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setFirstName('');
    setLastName('');
    setBirthDate('');
    setEmail('');
    setPhone('');
    setId('');
    setUuid('');

    setModal('show');
    setModalName('Add New Customer');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setModalName('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      'first_name':firstName,
      'last_name':lastName,
      'birth_date':birthDate,
      'email':email,
      'phone':phone,
      'uuid':uuid,
      'created_by':userId
    };

    try{
      if(uuid===''){
        //Save to Customers
        await axios.post('/api/customers/', data, config).then(result=>{
          alert(result.data.message);
          fetchTable();
          setModal('hide');
        }, (err)=>{
          console.log(err);
        });
      } else {
        //Update Customers
        await axios.put('/api/customers/'+id, data, config).then(result=>{
          alert(result.data.message);
          fetchTable();
          setModal('hide');
        }, (err)=>{
          console.log(err);
        });
      }
    } catch(e){
      console.log(e);
    }

  }

  function formatDate(date) {
    const d = new Date(date);
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const day = d.getDate().toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }
  
  return (
    role.toLowerCase()!='customer'?
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
      <div className="row">
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className="menu-icon-page tf-icons bx bxs-user-account"></span></div>
                  <div className="details"><h6>Total Customers</h6><p><b><span className="increase">442</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-user' ></span></div>
                  <div className="details"><h6>Indirect Customers</h6><p><b><span className="increase">147</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-line-chart' ></span></div>
                  <div className="details"><h6>Customer Growth</h6><p><b><span className="increase">25%</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bxs-shopping-bags"></i></div>
                  <div className="details"><h6>Total GMV Orders</h6><p><b><span className="increase">$258,000.00</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bx-gift"></i></div>
                  <div className="details"><h6>Total GMV Products</h6><p><b><span className="increase">1.2K</span></b></p></div>
              </div>
          </div>
        </div>         
      </div>
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header"><span className='bx bxs-user-account'></span> Customers
                {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                {isLoading?
                  <div className="table-responsive text-nowrap">
                    <table className="table">
                    <thead>
                      <tr>
                        {localStorage.getItem('profileRole').toLowerCase()  == 'admin' && <th>Store</th>}
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Join Date</th>
                        <th>Source</th>
                        <th>Source SubType</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
                  </table>
                  </div>
                  :
                  <div className="table-responsive text-nowrap"  dangerouslySetInnerHTML={{ __html: table }}>
                  </div>
                }
              </div>
          </div>      
      </div>
      <div className={modal==='show'?'offcanvas offcanvas-end show':'offcanvas offcanvas-end hide'} id="add-new-record">
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="exampleModalLabel">{modalName}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClose}></button>
        </div>
        
        <div className="offcanvas-body flex-grow-1">
          <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
          <input type="hidden" id='uuid' name='uuid' defaultValue={uuid}></input>
          <input type="hidden" id='id' name='id' defaultValue={id}></input>
            <div className="col-sm-12">
              <label className="form-label">First Name</label>
              <div className="input-group input-group-merge">
                <span id="first_name" className="input-group-text"><i className="bx bx-detail"></i></span>
                <input type="text" id="first_name" className="form-control dt-full-name" name="first_name" defaultValue={firstName} onChange={(e)=>setFirstName(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">Last Name</label>
              <div className="input-group input-group-merge">
                <span id="last_name" className="input-group-text"><i className="bx bx-detail"></i></span>
                <input type="text" className="form-control" name="last_name" defaultValue={lastName} id="last_name" onChange={(e)=>setLastName(e.target.value)}>
                </input>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">Birthday</label>
              <div className="input-group input-group-merge">
                <span id="birth_date" className="input-group-text"></span>
                <input type="date" id="birth_date" className="form-control dt-full-name" name="birth_date" defaultValue={birthDate} onChange={(e)=>setBirthDate(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">E-mail Address</label>
              <div className="input-group input-group-merge">
                <span id="email" className="input-group-text"><i className="bx bxs-envelope"></i></span>
                <input type="text" className="form-control" name="email" value={email} id="email" onChange={(e)=>setEmail(e.target.value)}>
                </input>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">Phone</label>
              <div className="input-group input-group-merge">
                <span id="phone" className="input-group-text"><i className="bx bxs-phone"></i></span>
                <input type="text" className="form-control" name="phone" value={phone} id="phone" onChange={(e)=>setPhone(e.target.value)}>
                </input>
              </div>
            </div>
            <div className="col-sm-12">
              <button type="submit" className="btn btn-primary data-submit me-sm-3 me-1" onClick={handleSubmit}>Submit</button>
              <button type="reset" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
            </div>
          </form>

        </div>
      </div>
    </div>:
    <div></div>
  )
}

export default Customers