import { useEffect, useState } from "react"
import axios from "../api/axios";
//import { useNavigate } from "react-router-dom";

const CustomerSignUp = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isOnProcess, setIsOnProcess]=useState(false);
    const [redirectUrl, setRedirectUrl]=useState(null);

    const openModal = () => {
      setIsModalOpen(true);
    };
  
    const closeModal = () => {
      setIsModalOpen(false);
    };
  
    
    const [id, setId] = useState(0);
    const [uuid, setUuid] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthDate, setBirthDate] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [agree, setAgree] = useState(false);
    const [errors, setErrors] = useState({});
    
  //const navigate = useNavigate();

  useEffect(() => {
    // Get the query parameter named "paramName" from the URL
    const queryParams = new URLSearchParams(window.location.search);
    const value = queryParams.get('redirect_url');

    // Update the state with the parameter value
    setRedirectUrl(value);
    console.log('Redirect URL : ' + redirectUrl);
  }, []); // Empty dependency array to run the effect only once on component mount

  const handleSubmit = (event) => {
    event.preventDefault();

    const newErrors = {};
    if(firstName === ''){
      newErrors.firstName = "First Name is Required";
    }
    if(lastName === ''){
      newErrors.lastName = "Last Name is Required";
    }
    if(birthDate === ''){
      newErrors.birthDate = "Birth Date is Required";
    }
    if(email === ''){
      newErrors.email = "Email is Required";
    }
    if(phone === ''){
      newErrors.phone = "Phone is Required";
    }

    if (!agree) {
      newErrors.agree = "You must agree to the terms";
    }

     // If there are errors, set them in state
     if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Submit the form if no errors
      openModal();
    }
  }

  const handleConfirm = async (event) => {
    event.preventDefault();
    closeModal();
    setIsOnProcess(true);
    const data = {
      'name':firstName +' '+ lastName,
      'email':email,
      'password':'123456',
      'role':'customer',
      'birth_date':birthDate,
      'phone':phone
    };
    
    try{
      await axios.post('/api/register', data).then(response=>{
          const data = {
            'first_name':firstName,
            'last_name':lastName,
            'birth_date':birthDate,
            'email':email,
            'phone':phone,
            'source':'shopify button',
            'uuid':uuid
          };    
          axios.post('/api/customers/signup', data).then(result=>{
              alert(result.data.message);
              setUuid('');
              setFirstName('');
              setLastName('');
              setPhone('');
              setEmail('');
              setBirthDate('');
              setId('');

              if(redirectUrl === null){
                alert("Can't find a redirect url.");
                setIsOnProcess(false);
                closeModal();
              } else {
                const newRedirectUrl = redirectUrl + '?token=' + response.data.user_id;
                window.location.href = newRedirectUrl;
              }
            }, (err)=>{
              console.log(err);
              alert('Please supply all fields.');
              closeModal();
              setIsOnProcess(false);
            });
        }, (err)=>{
          console.log(err);
          alert('Please supply all fields.');
          closeModal();
          setIsOnProcess(false);
        });
    } catch(e){
      console.log(e);
      closeModal();
      setIsOnProcess(false);
    }
  }

  return (
    <div className="container-xxl align-items-center">
      <div className="authentication-wrapper container-p-y sign-up-center">
          <div className="authentication-inner">
          <div className="card">
              <div className="card-body">
              <div className="text-center">
                  <img className="app-brand-logo-app signup-logo-img" alt="" src="../gyfhint-logo.png"></img>
              </div>
              <h5 className="mb-2 text-center">Create Customer Profile</h5><br/>
              <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
                  <input type="hidden" id='uuid' name='uuid' defaultValue={uuid}></input>
                  <input type="hidden" id='id' name='id' defaultValue={id}></input>
                  <div className="col-sm-6">
                      <label className="form-label">First Name</label>
                      <div className="input-group input-group-merge">
                      <span id="first_name" className="input-group-text"><i className="bx bx-detail"></i></span>
                        <input type="text" id="first_name" className="form-control dt-full-name" name="first_name" defaultValue={firstName} onChange={(e)=>setFirstName(e.target.value) } required/>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <label className="form-label">Last Name</label>
                      <div className="input-group input-group-merge">
                      <span id="last_name" className="input-group-text"><i className="bx bx-detail"></i></span>
                        <input type="text" className="form-control" name="last_name" defaultValue={lastName} id="last_name" onChange={(e)=>setLastName(e.target.value)} required>
                      </input>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <label className="form-label">Birthday</label>
                      <div className="input-group input-group-merge">
                      <span id="birth_date" className="input-group-text"></span>
                        <input type="date" id="birth_date" className="form-control dt-full-name" name="birth_date" defaultValue={birthDate} onChange={(e)=>setBirthDate(e.target.value)} required/>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <label className="form-label">E-mail Address</label>
                      <div className="input-group input-group-merge">
                      <span id="email" className="input-group-text"><i className="bx bxs-envelope"></i></span>
                        <input type="text" className="form-control" name="email" value={email} id="email" onChange={(e)=>setEmail(e.target.value)} required>
                      </input>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <label className="form-label">Phone</label>
                      <div className="input-group input-group-merge">
                      <span id="phone" className="input-group-text"><i className="bx bxs-phone"></i></span>
                        <input type="text" className="form-control" name="phone" value={phone} id="phone" onChange={(e)=>setPhone(e.target.value)} required>
                      </input>
                      </div>
                  </div>
                  <div className="col-sm-12">
                      <br />
                      <input type="checkbox" checked={agree} onChange={(e) => setAgree(e.target.checked)} required />
                  &nbsp; I agree to receive messages from GyftHint at the phone number provided above notifying me of upcoming gifting events for my friends and family. I understand data rates may apply. I may reply STOP to opt out at any time.
                  </div>
                  <div className="col-sm-12">
                      <br />
                      { !isOnProcess?
                        <button type="submit" className="btn btn-primary me-sm-3 me-1">Send my Code</button>:
                        <button className="btn btn-primary d-grid w-100" disabled type="submit">Loading...</button>
                      }
                  </div>
                </form>

                {isModalOpen && (
                  <div id="twoFAModal" className="modal fade show" aria-modal="true" role="dialog" style={{display:'block'}}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                          <div className="modal-header">
                              <h5 className="modal-title">Two-Factor Authentication (2FA)</h5>
                              <button type="button" className="close" data-dismiss="modal" onClick={closeModal}>×</button>
                          </div>
                          <div id="twoFAForm" style={{maxWidth:'500px', width:'100%', margin:'auto'}}>
                              <input type="hidden" name="email" />
                              <div className="modal-body">
                                <p>Check your email for a 6-digit OTP to complete your login. Please enter the OTP to proceed.</p>
                                <div className="clear"></div>
                                <div className="row">
                                    <div className="col-2">
                                      <input type="number" name="otp[0]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                    <div className="col-2">
                                      <input type="number" name="otp[1]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                    <div className="col-2">
                                      <input type="number" name="otp[2]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                    <div className="col-2">
                                      <input type="number" name="otp[3]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                    <div className="col-2">
                                      <input type="number" name="otp[4]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                    <div className="col-2">
                                      <input type="number" name="otp[5]" min="0" max="9" maxLength="1" className="form-control input-2fa" />
                                    </div>
                                </div>
                                <div className="clear10"></div>
                                <p className="text-right mg0"><span className="text-muted">Did not received the email?</span> <a className="text-success" id="resend-otp" href="#">Resend OTP</a></p>
                                <div className="clear20"></div>
                                <button type="submit" className="btn btn-primary input-xlg" style={{width:'100%'}} onClick={handleConfirm}>Continue</button>
                                <div className="clear20"></div>
                              </div>
                          </div>
                        </div>
                    </div>
                  </div>
                )}
              </div>
          </div>
          </div>
      </div>
    </div>
  )
}

export default CustomerSignUp