import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import axios from "../api/axios";

const MerchantDetails = () => {
  // Ensure role state is properly initialized
  const [role, setRole] = useState(localStorage.getItem('profileRole') || '');

  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnProcess, setIsOnProcess]=useState(false);

  const { id } = useParams();

  const [users, setUsers] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const [storeId, setStoreId] = useState(0);
  const [storeName, setStoreName] = useState('');
  const [licenseAmount, setLicenseAmount] = useState(''); // Initialize as empty string
  const [isPaid, setIsPaid] = useState('');
  const [storeOwner, setStoreOwner] = useState('');
  const [eCommerceSite, setEcommerceSite] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [source, setSource] = useState('');
  const [sourceSubType, setsourceSubType] = useState('');
  const [customerId, setCustomerId] = useState(0);
  const [noOfLicenses, setNoOfLicense] = useState(0); // Initialize as 0
  const [label, setLabel] = useState('');
  const [addOnPrice, setAddOnPrice] = useState(0); // Initialize as 0
  const [licenseId, setLicenseId] = useState(0); // Initialize as 0
  const [noOfLicenseUsed, setNoOfLicenseUsed] = useState(0); // Initialize as 0
  const [noOfLicensePaid, setNoOfLicensePaid] = useState(0); // Initialize as 0
  const [commissionPercentage, setCommissionPercentage] = useState('0.00'); // Initialize as string
  const [commissionPercentageAmount, setCommissionPercentageAmount] = useState(0); // Initialize as 0
  const [affiliateGroup, setAffiliateGroup] = useState('GyftHint'); // Initialize with default value

  const token = localStorage.getItem('accessToken');
  if (token === '' || token === undefined || token === null) {
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  const userId = localStorage.getItem('userId');
  const config = {
    headers: { Authorization: `Bearer ${token}` }
  };

  useEffect(() => {
    fetchModuleDetails();
    fetchDetails();
    setIsLoading(true);
  }, []);

  const fetchModuleDetails = async () => {
    const module_nav_link = '/customers';
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    };
    try {
      const result = await axios.post('/api/module-access-details', data, config);
      setModuleName(result.data.menu_access[0]['module_name']);
      setModuleDescription(result.data.menu_access[0]['module_description']);
      const icon = result.data.menu_access[0]['module_icon'];
      setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
      setCanAdd(result.data.menu_access[0]['can_add']);
      setCanEdit(result.data.menu_access[0]['can_update']);
      setCanDelete(result.data.menu_access[0]['can_delete']);
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDetails = async () => {
    setIsLoading(true);
    try {
      const result = await axios.get('/api/merchants/details/' + id, config);
      console.log(result);
      const data = result.data.data[0];

      setStoreId(data['id']);
      setStoreName(data['store_name']);
      setStoreOwner(data['store_owner']);
      setEcommerceSite(data['myshopify_domain']);
      setEmail(data['store_email']);
      setAddress(data['address']);
      setPhone(data['phone']);
      setSource(data['source']);
      setsourceSubType(data['source_subtype']);
      setCustomerId(data['id']);
      setLabel(data['label']);
      setAddOnPrice(data['add_on_price']);
      setLicenseId(data['license_id']);
      setNoOfLicenseUsed(data['no_of_users']);
      setNoOfLicensePaid(data['no_of_users_paid']);
      const commissionPercentage = data['commission_percentage'].toFixed(2);
      setCommissionPercentage(commissionPercentage.toString()); // Ensure it is a string
      setCommissionPercentageAmount(data['commission_amount'].toFixed(2));
      setAffiliateGroup(data['affiliate_group']);
      const totalAmount = data['total_amount'].toFixed(2);
      setLicenseAmount('$ ' + (totalAmount * 12).toFixed(2));
      setIsPaid(data['is_paid']);
      setUsers(result.data.users);
      setLen(result.data.users.length);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New User License');
  };

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsOnProcess(true);
    const data = {
      'store_id': storeId,
      'label': label + ' - Add On',
      'noOfLicense': noOfLicenses,
      'license_id': licenseId,
      'add_on_price': addOnPrice
    };
    console.log(data);
    try {
      const result = await axios.post('/api/licenses/insertUserLicense', data, config);
      setIsOnProcess(false);
      alert(result.data.message);
      fetchDetails();
      setModal('hide');
    } catch (e) {
      console.log(e);
      setIsOnProcess(false);
    }
  };

  function formatDate(date) {
    const d = new Date(date);
    const month = d.getMonth() + 1;
    const day = d.getDate();
    const year = d.getFullYear();
    return `${month}/${day}/${year}`;
  }

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
        <div id="table-list-container" className="col-md-3">
          <div className="card">
            <h5 className="card-header"><span className='bx bx-list-ul'></span> Merchant Details
              <hr /></h5>
            <div className="card-body">
              <label className="form-label">Merchant:</label>
              <input type="text" className="form-control" value={storeName || ''} disabled></input>
              <label className="form-label">E-Commerce Site:</label>
              <input type="text" className="form-control" value={eCommerceSite || ''} disabled></input>
              <label className="form-label">Primary Contact:</label>
              <input type="text" className="form-control" value={storeOwner || ''} disabled></input>
              <label className="form-label">Annual License Amount: {isPaid === 0 ? <span className="badge bg-warning me-1">Unpaid</span> : <span className="badge bg-primary me-1">Paid</span>}</label>
              <input type="text" className="form-control" value={licenseAmount || ''} disabled></input>
              <label className="form-label">Email:</label>
              <input type="text" className="form-control" value={email || ''} disabled></input>
              <label className="form-label">Phone:</label>
              <input type="text" className="form-control" value={phone || ''} disabled></input>
              <label className="form-label">Address:</label>
              <input type="text" className="form-control" value={address || ''} disabled></input>
              <label className="form-label">Source:</label>
              <input type="text" className="form-control" value={source || ''} disabled></input>
              <label className="form-label">Source Sub-Type:</label>
              <input type="text" className="form-control" value={sourceSubType || ''} disabled></input>
              <label className="form-label">Affiliate Group:</label>
              <input type="text" className="form-control" value={affiliateGroup || ''} disabled></input>
              <label className="form-label">Commission Percentage:</label>
              <input type="text" className="form-control" value={commissionPercentage || ''} disabled></input>
              <label className="form-label">Commission Amount:</label>
              <input type="text" className="form-control" value={'0.00'} disabled></input>
            </div>
          </div>
        </div>
        <div id="table-list-container" className="col-md-9">
          <div className="card">
            <h5 className="card-header"><span className='bx bx-user'></span> Users
              <a className="btn btn-warning btn-sm ms-2" href="/merchants" > Back &nbsp;</a>
              <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>
              <span style={{ float: 'right' }}>License Used: <span className="badge bg-label-primary me-1">{noOfLicenseUsed + '/' + noOfLicensePaid}</span></span>
            </h5>
            <div className="table-responsive text-nowrap">
              <table className="table">
                <thead>
                  <tr>
                    <th>User</th>
                    <th>User Title</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>License Type</th>
                    <th className="th_decimal_values">Cost</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {(Array.isArray(users) ? users : []).map(user => (
                    <tr key={user.id}>
                      <td>{user.name || ''}</td>
                      <td>{''}</td>
                      <td>{user.email || ''}</td>
                      <td><span>{user.phone || ''}</span></td>
                      <td><span className="badge bg-label-primary me-1">{user.label ? user.label.toUpperCase() : ''}</span></td>
                      <td className="td_decimal_values"><span>{'$ ' + (user.price ? user.price.toFixed(2).toLocaleString() : '0.00')}</span></td>
                      <td>{user.start_date ? formatDate(user.start_date) : ''}</td>
                      <td>{user.expiry_date ? formatDate(user.expiry_date) : ''}</td>
                      <td>{'Active'}</td>
                    </tr>
                  ))}
                  {len === 0 && !isLoading &&
                    <tr>
                      <td colSpan={7}>No records found.</td>
                    </tr>
                  }
                  {isLoading &&
                    <tr><td colSpan={9}><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className={modal === 'show' ? 'offcanvas offcanvas-end show' : 'offcanvas offcanvas-end hide'} id="add-new-record">
          <div className="offcanvas-header border-bottom">
            <h5 className="offcanvas-title" id="exampleModalLabel">{modalName}</h5>
            <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClose}></button>
          </div>
          <div className="offcanvas-body flex-grow-1">
            <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
              <div className="col-sm-12">
                <label className="form-label">No of Licenses</label>
                <div className="input-group input-group-merge">
                  <span id="label" className="input-group-text"><i className="bx bx-label"></i></span>
                  <input type="number" id="noofliceses" className="form-control dt-full-name" name="label" defaultValue={''} onChange={(e) => setNoOfLicense(e.target.value || 0)} />
                </div>
              </div>
              <br />
              <div className="col-sm-12">
                {!isOnProcess ?
                  <button type="submit" className="btn btn-primary data-submit me-sm-3 me-1" onClick={handleSubmit}>Save</button> :
                  <button type="button" className="btn btn-primary data-submit me-sm-3 me-1" disabled>Saving...</button>
                }
                <button type="reset" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas" onClick={handleClose}>Cancel</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MerchantDetails;
