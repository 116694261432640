import React, { useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import axios from "../api/axios";

const Sidebar = () => {
  const [isOnProcess, setIsOnProcess]=useState(false);
  const basePathName = window.location.pathname;
  const basePath = basePathName.split('/')[1];  // Extract the second segment
  const pathName = `/${basePath}`;
  const location = useLocation();
  const role = useState(localStorage.getItem('profileRole').toLowerCase()); 
  const store_logo = localStorage.getItem('currentActiveStoreLogo')==='null' ? '../gyfhint-logo.png' : axios.defaults.baseURL + localStorage.getItem('currentActiveStoreLogo');

  //const navigate = useNavigate();
  const token = localStorage.getItem('accessToken');
  let menu_access = window.localStorage.getItem('menuAccess');
  if(menu_access === '' || menu_access === undefined || token === '' || token === undefined){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  } else {
    menu_access = JSON.parse(menu_access);
    if(pathName === '/login'){
      window.location.href = '/';
    }
  }
  
  const user_id = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const data = {
    'store_id' : localStorage.getItem('currentActiveStoreId')
  }

  const handleUpgrade = async(event) => {
      event.preventDefault();
      setIsOnProcess(true);
      try{
        await axios.post('/api/licenses/upgrade', data, config).then(result =>{
          window.open(result.data.link, '_blank');
        }, (err)=>{
          alert(err);
          setIsOnProcess(false);
        });
      } catch (e){
        console.log(e);
        alert(e);
        setIsOnProcess(false);
      }
  }

  return (
        <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
          <div className="app-brand demo">
            <a href="/" className="app-brand-link">
              <span className="app-brand-logo demo">
                <div className="logo-img text-center">
                    <img className="app-brand-logo-app" alt="" src={store_logo}></img>
                </div>
              </span>
            </a>

            <a href="/" className="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
              <i className="bx bx-chevron-left bx-sm align-middle"></i>
            </a>
          </div>
          <div style={{clear:'both',height: '10px'}}></div>
          
          {localStorage.getItem('profileRole').toLowerCase() === 'admin' &&
            <h5 className="m-0 text-center text-black"><b>Admin Panel</b></h5>
          }
          {localStorage.getItem('profileRole').toLowerCase() === 'admin' &&
            <div style={{clear:'both',height: '20px'}}></div>
          }

          <div className="menu-inner-shadow"></div>
          <ul className="menu-inner py-1">
            {menu_access.map((menu) => {
                const isActive = menu.module_nav_link === pathName;
                const isDeleted = menu.m_is_deleted === 2;
                const menuItemClass = isActive ? "menu-item active" : "menu-item";
                const disabledClass = isDeleted ? "li-disabled" : "";

                return (
                    <li className={`${menuItemClass} ${disabledClass}`} key={menu.m_uuid}>
                        <Link to={menu.module_nav_link} className="menu-link">
                            <i className={menu.module_icon + ' ' + disabledClass}></i>
                            <div data-i18n={menu.module_name} className={disabledClass}>
                                {localStorage.getItem('profileRole').toLowerCase() === 'merchant'
                                    ? menu.module_name
                                    : menu.module_description}
                            </div>
                        </Link>
                    </li>
                );
            })}
        </ul>
          {localStorage.getItem('profileRole').toLowerCase()  == 'merchant' && 
          <div className="buy-now d-none">
            <a onClick={handleUpgrade} target="_blank" rel="noopener noreferrer" style={{marginLeft:'7px'}}>
              <img src="../assets/upgrade-banner-light-68cf2382.png" alt="upgrade-banner" transition="scale-transition" className="upgrade-banner mx-auto" style={{maxWidth:'230px'}}></img>
            </a>
          </div>
          
          }
          
          {localStorage.getItem('profileRole').toLowerCase()  == 'merchant' && 
          <div className="buy-now d-none">
            <a href="mailto:sales@gyfthint.com" target="_blank" className="btn btn-warning btn-buy-now">Upgrade or Add Licenses</a>
          </div>
          }
        </aside>
  )
}

export default Sidebar