import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import DataTable from "react-data-table-component";

const CuratedHints = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [defaultStatus, setDefaultStatus] = useState(false);
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');
  const [len, setLen] = useState(0);

  /* setting of forms */
  const [hints, setHints] = useState([]);
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
  const [storeId, setStoreId] = useState(currentActiveStoreId);
  /* setting of forms ends here */

  /* token and headers */
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here */

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        alert(err.response.data.message);
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    let store_id = storeId === ''? null:storeId;
    try{
      await axios.get('/api/hints/' + store_id, config ).then(result => {
        setHints(result.data.data);
        setLen(result.data.data.length);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setModal('show');
    setDefaultStatus(true);
    setModalName('Add New Products');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setDefaultStatus(false);
    setModalName('');
  }

  const columns = [
    { name: "STORE", selector: (row) => row.store_name, omit: role.toLowerCase() !== 'admin' },
    { name: "IMAGE", cell: (row) => <img src={row.product_images_links} alt="product" style={{ width: 'auto', height: '100px' }} /> },
    { name: "PRODUCT NAME", selector: (row) => row.product_name, sortable: true },
    { name: "PRODUCT URL", cell: (row) => <a className="btn btn-primary btn-sm" href={row.product_url.includes('https://') ? row.product_url : 'https://' + row.product_url} target="_blank" rel="noreferrer">View Product</a> },
    { name: "SOURCE", selector: (row) => row.source, sortable: true },
    { name: "CATEGORY", selector: (row) => '', sortable: true },
    { name: "QTY", selector: (row) => row.product_quantity, sortable: true },
    { name: "PRICE", selector: (row) => row.product_price, sortable: true },
    { name: "ACTION", cell: (row) => canEdit === 1 ? <a href="#"><i className='bx bx-edit'></i></a> : '', omit: canEdit !== 1 }
  ];

  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="main-kpis">
        <div className="row">
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className="menu-icon-page tf-icons bx bx-gift"></span></div>
                  <div className="details"><h2>Total Products</h2><p><b><span className="increase">3,980</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-line-chart' ></span></div>
                  <div className="details"><h2>Total GMV</h2><p><b><span className="increase">$258,000.00</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><b><i className="menu-icon-page tf-icons bx bx-chart"></i></b></div>
                  <div className="details"><h2>GMV Growth</h2><p><b><span className="increase">32%</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><span className='menu-icon-page bx bx-award' ></span></div>
                  <div className="details"><h2>Top Category</h2><p><b><span className="increase">Clothing</span></b></p></div>
              </div>
          </div>
          <div className="col">
              <div className="kpi">
                  <div className="icon"><i className="menu-icon-page tf-icons bx bxs-award"></i></div>
                  <div className="details"><h2>Top Product</h2><p><b><span className="increase">Camber 305 Max...</span></b></p></div>
              </div>
          </div>
        </div> 
      </div>
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header">
                  <span className='bx bx-gift'></span> {role.toLowerCase() === 'admin' ? 'Products' : 'Products'}
                  {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                {isLoading && 
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Product Url</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                      <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                    </tbody>
                  </table>
                </div>}
                {!isLoading && len ===0 &&
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Store</th>
                        <th>Image</th>
                        <th>Product Name</th>
                        <th>Product Url</th>
                        <th>Source</th>
                        <th>Category</th>
                        <th>QTY</th>
                        <th>Price</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td colSpan={9}>No records found.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>}
                <div className="table-responsive text-nowrap">
                  <DataTable
                    columns={columns}
                    data={hints}
                    noDataComponent=""
                    pagination
                    highlightOnHover
                  />
                </div>
              </div>
          </div>      
      </div>
    </div>
  )
}

export default CuratedHints;
