import React from "react";

const GifthintValue = () => {
  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
      <div className="row">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header">Total Order Value (GMV)</div>
            <div className="card-body">
              <h1>$172,445.00</h1>
              <p className="text-success">+5.2%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header">Average Order Value</div>
            <div className="card-body">
              <h1>$65.00</h1>
              <p className="text-success">+2.5%</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-header">Sustainability Impact</div>
            <div className="card-body">
              <h1>5,960 kg/CO2</h1>
              <p>3,072 packages to landfill</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>45%</h1>
              <p>Product Conversion Rate</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>6.2%</h1>
              <p>Cart Abandonment Rate</p>
              <span className="arrow">&#x2192;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>0.9%</h1>
              <p>Product Service Rate</p>
              <span className="arrow">&#x2192;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>442</h1>
              <p>Customers</p>
              <span className="arrow">&#x2193;</span>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>147</h1>
              <p>Indirect Customer Acquisition</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>1.2k</h1>
              <p>Network Shared Products</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>2.2k</h1>
              <p>Transferred Products</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="card text-center">
            <div className="card-body">
              <h1>9,555</h1>
              <p>Indirect GMV Added</p>
              <span className="arrow">&#x2191;</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default GifthintValue