import React, { useState } from 'react'
import axios from '../api/axios';

const Settings = () => {
  const [profileName] = useState(localStorage.getItem('profileName'));
  const [email] = useState(localStorage.getItem('profileEmail'));
  const [isOnProcess, setIsOnProcess]=useState(false);
  const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };

  const store_logo = localStorage.getItem('currentActiveStoreLogo')==='null' ? '../gyfhint-logo.png' : axios.defaults.baseURL + localStorage.getItem('currentActiveStoreLogo');
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewURL, setPreviewURL] = useState(store_logo);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage(file);
      setPreviewURL(URL.createObjectURL(file));
    } else {
      setSelectedImage(null);
      setPreviewURL(null);
    }
  };

  // Handle file upload
  const handleFileUpload = async () => {
    setIsOnProcess(true);
    if (!selectedImage) {
      alert("Please select a file first!");
      setIsOnProcess(false);
      return;
    }

    // Create a FormData object to hold the file data
    const formData = new FormData();
    formData.append('file', selectedImage);
    formData.append('uuid', currentActiveStoreId)

    try {
      await axios.post('/api/stores/logo/upload', formData, config).then(result=>{
        console.log(result.data.fileUrl);
        alert(result.data.message);
        localStorage.setItem('currentActiveStoreLogo', result.data.fileUrl);
        window.location.reload();
        setIsOnProcess(false);
      }, (err)=>{
        console.log(err);
        setIsOnProcess(false);
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsOnProcess(false);
      alert('Error uploading file.');
    }
  };

  return (
    <div className="content-wrapper">
        <div className="container-xxl flex-grow-1 container-p-y">

              <div className="row">
                <div className="col-md-12">
                  <div className="card mb-4">
                    <h5 className="card-header">{localStorage.getItem('profileRole').toUpperCase()=='MERCHANT'?'Merchant':'Profile'} Logo - {localStorage.getItem('currentActiveStore')}</h5>
                    <div className="card-body">
                      <div className="d-flex align-items-start align-items-sm-center gap-4">
                        <img
                          src={previewURL}
                          alt="user-avatar"
                          className="d-block"
                          height="30%"
                          width="30%"
                          id="uploadedAvatar" />
                        <div className="button-wrapper">
                          <label className="btn btn-primary me-2 mb-4" tabIndex="0">
                            <span className="d-sm-block">Upload New Logo</span>
                            <i className="bx bx-upload d-block d-sm-none"></i>
                            <input
                              type="file"
                              id="upload"
                              className="account-file-input"
                              hidden
                              accept="image/png, image/jpeg" onChange={handleImageChange}/>
                          </label>
                          {!isOnProcess ? 
                            <button type="button" className="btn btn-outline-secondary account-image-reset mb-4">
                              <i className="bx bx-reset d-block d-sm-none"></i>
                              <span className="d-sm-block" onClick={handleFileUpload}>Save</span>
                            </button> :
                            <button type="button" className="btn btn-outline-secondary account-image-reset mb-4" disabled>
                              <i className="bx bx-reset d-block d-sm-none"></i>
                              <span className="d-sm-block">Saving...</span>
                            </button> 
                          }
                          <p className="text-muted mb-0">Allowed JPG, GIF or PNG</p>
                        </div>
                      </div>
                    </div>
                    <hr className="my-0 " />
                    <div className="card-body">
                    </div>
                  </div>
                </div>
              </div>
            </div>
    </div>
  )
}

export default Settings