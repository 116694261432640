import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const Promotions = () => {
    const [role, setRole] = useState(localStorage.getItem('profileRole'));
    const [modal, setModal] = useState('hide');
    const [defaultStatus, setDefaultStatus] = useState(false);
    const [modalName, setModalName] = useState('');
    const [canAdd, setCanAdd] = useState(0);
    const [canEdit, setCanEdit] = useState(0);
    const [canDelete, setCanDelete] = useState(0);
    const metric = ['low', 'medium', 'high'];
    const [isLoading, setIsLoading] = useState(false);
  
    /* setting of forms */
    const [hints, setHints] = useState([]);
    const [len, setLen] = useState(0);
    const [moduleName, setModuleName] = useState('');
    const [moduleDescription, setModuleDescription] = useState('');
    const [moduleIcon, setModuleIcon] = useState('');
    const currentActiveStoreId = localStorage.getItem('currentActiveStoreId');
    const [storeId, setStoreId] = useState(currentActiveStoreId);
    /* setting of forms ends here */
  
    /* token and headers*/
    const token = localStorage.getItem('accessToken');
    if(token === '' || token === undefined || token === null){
      localStorage.setItem('accessToken', '');
      localStorage.setItem('menuAccess', []);
      localStorage.setItem('profileRole', '');
      window.location.href = '/login';
    }
    
    const userId = localStorage.getItem('userId');
    const config = {
        headers: { Authorization: `Bearer ${token}` }
    };
    /* token and headers ends here*/
  
    useEffect(() => {
      fetchModuleDetails();
      fetchTable();
      setIsLoading(true);
    }, [])
  
    const fetchModuleDetails = async () => {
      const module_nav_link = window.location.pathname;
      const data = {
        'uuid': userId,
        'module_nav_link': module_nav_link
      }
      try{
        await axios.post('/api/module-access-details', data, config).then(result =>{
          setModuleName(result.data.menu_access[0]['module_name']);
          setModuleDescription(result.data.menu_access[0]['module_description']);
          const icon = result.data.menu_access[0]['module_icon'];
          setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
          setCanAdd(result.data.menu_access[0]['can_add']);
          setCanEdit(result.data.menu_access[0]['can_update']);
          setCanDelete(result.data.menu_access[0]['can_delete']);
        }, (err)=>{
          alert(err.response.data.message);
        });
      } catch (e){
        console.log(e);
      }
    }
  
    const fetchTable = async() => {
      try{
        await axios.get('/api/hints/' + storeId, config ).then(result => {
          setHints(result.data.data);
          setLen(result.data.data.length);
          setIsLoading(false);
        })
      }catch(e){
        console.log(e);
        setIsLoading(false);
      }
    }
  
    const handleAddNew = (event) => {
      event.preventDefault();
      setModal('show');
      setDefaultStatus(true);
      setModalName('Add New Products');
    }
  
    const handleClose = (event) => {
      event.preventDefault();
      setModal('hide');
      setDefaultStatus(false);
      setModalName('');
    }
    const getRandomElement = (arr) => {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex].toUpperCase();
    };

    const getRandomNumber = () => {
        const randomIndex = Math.floor(Math.random() * 100);
        return randomIndex;
    };
    
  return (
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
       <div className="main-kpis">
            <div className="row">
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                        <div className="icon">👥</div>
                        <div className="details">
                        <h2>Returning Users</h2>
                        <p>2,653 <span className="increase">+2.74%</span></p>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                        <div className="icon">📉</div>
                        <div className="details">
                            <h2>Bounce Rate</h2>
                            <p>23.64% <span className="increase">+0.98%</span></p>
                        </div>
                    </div>
                </div>
                <div className="col-4 col-lg-3">
                    <div className="kpi">
                    <div className="icon">✅</div>
                    <div className="details">
                        <h2>Conversion Rate</h2>
                        <p>78% <span className="increase">+3.89%</span></p>
                    </div>
                    </div>
                </div>
                <div className="col-4 col-lg-3">
                <div className="kpi">
                    <div className="icon"><i className="menu-icon-page tf-icons bx bx-money"></i></div>
                    <div className="details">
                    <h2>Session Duration</h2>
                    <p>00:25:30 <span className="increase">+1.45%</span></p>
                    </div>
                </div>
                </div>
            </div> 
       </div>
       <div className="row mb-5">
            <div id="table-list-container" className="col-md-12">
                <div className="card">                                    
                    <div className="table-responsive text-nowrap">
                    <table id="datatable" className="table table-striped">
                        <thead>
                        <tr>
                            <th>Image</th>
                            <th>Product Name</th>
                            <th>Stock</th>
                            <th>Price</th>
                            <th>Low Stock Metric</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody className="table-border-bottom-0">
                            {(Array.isArray(hints)?hints:[]).map(hint=>(
                                <tr key={hint.id}> 
                                <td><img src={hint.product_images_links} style={{width:'auto', height:'100px'}}></img></td>
                                <td><span className="fw-medium">{hint.product_name}</span></td>
                                <td><span>{getRandomNumber()}</span></td>
                                <td><span>{hint.product_price}</span></td>
                                <td><span>{getRandomElement(['low', 'medium', 'high'])}</span></td>
                                <td><button className="btn btn-warning btn-sm">PROMOTE</button></td>
                                </tr>
                            ))}
                            {len===0 && !isLoading &&
                              <tr>
                                <td colSpan={7}>No records found.</td>
                              </tr>
                            }
                            {isLoading &&
                              <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                            }
                            {isLoading &&
                            <tr><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td><td><div className="loading-content"><h5 className="loading-short"></h5></div></td></tr>
                            }
                            {isLoading &&
                            <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                            }
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>   
      </div> 
    </div>
  )
}

export default Promotions