import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

// Register necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const Chart = () => {
  const data = {
    labels: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
    datasets: [
      {
        label: 'Products',
        data: [65, 59, 80, 81, 56, 55, 40, 45, 60, 50, 70, 75],
        borderColor: 'blue',
        fill: false
      },
      {
        label: 'Orders',
        data: [28, 48, 40, 19, 86, 27, 90, 60, 75, 80, 82, 70],
        borderColor: 'lightblue',
        fill: false
      }
    ]
  };

  const options = {
    responsive: true,
    scales: {
      x: {
        beginAtZero: true
      },
      y: {
        beginAtZero: true
      }
    }
  };

  return (
    <div>
      <Line data={data} options={options} />
    </div>
  );
};

export default Chart;
