import React, { useEffect, useState } from "react";
import axios from "../api/axios";

const License = () => {
  const [role, setRole] = useState(localStorage.getItem('profileRole'));
  const [modal, setModal] = useState('hide');
  const [modalName, setModalName] = useState('');
  const [canAdd, setCanAdd] = useState(0);
  const [canEdit, setCanEdit] = useState(0);
  const [canDelete, setCanDelete] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isOnProcess, setIsOnProcess]=useState(false);

  /* setting of forms */
  const [licenses, setLicenses] = useState([]);
  const [len, setLen] = useState(0);
  const [moduleName, setModuleName] = useState('');
  const [moduleDescription, setModuleDescription] = useState('');
  const [moduleIcon, setModuleIcon] = useState('');

  const [id, setId] = useState(0);
  const [uuid, setUuid] = useState('');
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [addonprice, setAddOnPrice] = useState('');
  const [image, setImage] = useState('');
  const [integration, setIntegration] = useState('shopify');
  const [isEnabled, setIsEnabled] = useState(true);
  const [isEnabledChecked, setIsEnabledChecked] = useState('checked');
  /* setting of forms ends here */

  /* token and headers*/
  const token = localStorage.getItem('accessToken');
  if(token === '' || token === undefined || token === null){
    localStorage.setItem('accessToken', '');
    localStorage.setItem('menuAccess', []);
    localStorage.setItem('profileRole', '');
    window.location.href = '/login';
  }

  const userId = localStorage.getItem('userId');
  const config = {
      headers: { Authorization: `Bearer ${token}` }
  };
  /* token and headers ends here*/

  useEffect(() => {
    fetchModuleDetails();
    fetchTable();
    setIsLoading(true);
  }, [])

  const fetchModuleDetails = async () => {
    const module_nav_link = window.location.pathname;
    const data = {
      'uuid': userId,
      'module_nav_link': module_nav_link
    }
    try{
      await axios.post('/api/module-access-details', data, config).then(result =>{
        setModuleName(result.data.menu_access[0]['module_name']);
        setModuleDescription(result.data.menu_access[0]['module_description']);
        const icon = result.data.menu_access[0]['module_icon'];
        setModuleIcon(icon.replace('menu-icon tf-icons bx ', ''));
        setCanAdd(result.data.menu_access[0]['can_add']);
        setCanEdit(result.data.menu_access[0]['can_update']);
        setCanDelete(result.data.menu_access[0]['can_delete']);
      }, (err)=>{
        alert(err.response.data.message);
      });
    } catch (e){
      console.log(e);
    }
  }

  const fetchTable = async() => {
    try{
      await axios.get('/api/licenses', config ).then(result => {
        setLicenses(result.data.data);
        setLen(result.data.data.length);
        setIsLoading(false);
      })
    }catch(e){
      console.log(e);
      setIsLoading(false);
    }
  }

  const fetchData = async(id) => {
    setModal('show');
    setLabel('');
    setDescription('');
    setPrice('');
    setAddOnPrice('');
    setIsEnabled(true);
    setId('');
    setIntegration('');
    setUuid('');
    try{
      await axios.get('/api/licenses/' + id, config ).then(result => {
        const data = result.data.data;
        setLabel(data.label);
        setDescription(data.description);
        setPrice(data.price.toFixed(2));
        setAddOnPrice(data.add_on_price.toFixed(2));
        setIsEnabled(data.is_enabled===1?true:false);
        setIntegration(data.integration);
        setId(data.id);
        setUuid(data.uuid);
      })
    }catch(e){
      console.log(e);
    }
  }

  const handleAddNew = (event) => {
    event.preventDefault();
    setLabel('');
    setDescription('');
    setPrice('');
    setAddOnPrice('');
    setIsEnabled(true);
    setId('');
    setIntegration('');
    setUuid('');

    setModal('show');
    setIsEnabled(true);
    setModalName('Add New License');
  }

  const handleClose = (event) => {
    event.preventDefault();
    setModal('hide');
    setIsEnabled(false);
    setModalName('');
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsOnProcess(true);
    const data = {
      'label':label,
      'description':description,
      'price':price,
      'add_on_price':addonprice,
      'image':image,
      'is_enabled':isEnabled?1:0,
      'integration':integration,
      'uuid':uuid,
      'created_by':userId
    };

    try{
      if(uuid===''){
        //Save to License
        await axios.post('/api/licenses/', data, config).then(result=>{
          setIsOnProcess(false);
          alert(result.data.message);
          fetchTable();
          setModal('hide');
        }, (err)=>{
          console.log(err);
          setIsOnProcess(false);
        });
      } else {
        //Update License
        await axios.put('/api/licenses/'+id, data, config).then(result=>{
          setIsOnProcess(false);
          alert(result.data.message);
          fetchTable();
          setModal('hide');
        }, (err)=>{
          console.log(err);
          setIsOnProcess(false);
        });
      }
    } catch(e){
      console.log(e);
      setIsOnProcess(false);
    }

  }
  
  return (
    role.toLowerCase()=='admin'?
    <div className="container-xxl flex-grow-1 container-p-y">
      <div className="row mb-12">
          <div id="table-list-container" className="col-md-12">
          <div className="card">
                <h5 className="card-header"><span className='bx bx-id-card'></span> Licenses
                {canAdd===1 && <button type="submit" className="btn btn-primary btn-sm ms-2" onClick={handleAddNew}> Add &nbsp; <i className="bx bxs-plus-circle"></i></button>}
                </h5>
                <div className="table-responsive text-nowrap">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Label</th>
                        <th className="th-long">Description</th>
                        <th className="th_decimal_values">Price</th>
                        <th>Integration</th>
                        <th>Status</th>
                        {canEdit===1 && <th>Action</th> }
                      </tr>
                    </thead>
                    <tbody className="table-border-bottom-0">
                      {(Array.isArray(licenses)?licenses:[]).map(license=>(
                        <tr key={license.uuid}> 
                          <td><span className="fw-medium">{license.label}</span></td>
                          <td className="td-long ellipsis"><span>{license.description}</span></td>
                          <td className="td_decimal_values"><span className="badge bg-label-primary me-1">$ {parseFloat(license.price).toFixed(2).toLocaleString()}</span></td>
                          <td><span>{license.integration.charAt(0).toUpperCase() + license.integration.slice(1)}</span></td>
                          <td>
                            <span>
                              {license.is_enabled===1?<span className="badge bg-label-success me-1">Enabled</span>:<span className="badge bg-label-danger me-1">Disabled</span>}
                            </span>
                          </td>
                          <td><a onClick={(e)=>fetchData(license.id)}><i className='bx bx-edit'></i></a></td>
                        </tr>
                      ))}
                      {len===0 && !isLoading &&
                        <tr>
                          <td colSpan={6}>No records found.</td>
                        </tr>
                      }
                      {isLoading &&
                        <tr><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td><td><div className="loading-content"><h5 className="loading-long"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div class="loading-content"><h5 class="loading-short"></h5></div></td><td><div class="loading-content"><h5 class="loading-short"></h5></div></td><td><div class="loading-content"><h5 class="loading-short"></h5></div></td><td><div class="loading-content"><h5 class="loading-short"></h5></div></td><td><div class="loading-content"><h5 class="loading-short"></h5></div></td></tr>
                      }
                      {isLoading &&
                       <tr><td><div class="loading-content"><h5 class="loading-long"></h5></div></td><td><div class="loading-content"><h5 class="loading-long"></h5></div></td><td><div class="loading-content"><h5 class="loading-long"></h5></div></td><td><div class="loading-content"><h5 class="loading-long"></h5></div></td><td><div class="loading-content"><h5 class="loading-long"></h5></div></td></tr>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
          </div>      
      </div>
      <div className={modal==='show'?'offcanvas offcanvas-end show':'offcanvas offcanvas-end hide'} id="add-new-record">
        <div className="offcanvas-header border-bottom">
          <h5 className="offcanvas-title" id="exampleModalLabel">{modalName}</h5>
          <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" onClick={handleClose}></button>
        </div>
        
        <div className="offcanvas-body flex-grow-1">
          <form className="add-new-record pt-0 row g-2" id="form-add-new-record" onSubmit={handleSubmit}>
          <input type="hidden" id='uuid' name='uuid' defaultValue={uuid}></input>
          <input type="hidden" id='id' name='id' defaultValue={id}></input>
            <div className="col-sm-12">
              <label className="form-label">LABEL</label>
              <div className="input-group input-group-merge">
                <span id="label" className="input-group-text"><i className="bx bx-label"></i></span>
                <input type="text" id="label" className="form-control dt-full-name" name="label" defaultValue={label} onChange={(e)=>setLabel(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">DESCRIPTION</label>
              <div className="input-group input-group-merge">
                <span id="description" className="input-group-text"><i className="bx bx-text"></i></span>
                <textarea rows={5} className="form-control" name="description" defaultValue={description} id="description" onChange={(e)=>setDescription(e.target.value)}>
                </textarea>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">PRICE</label>
              <div className="input-group input-group-merge">
                <span id="price" className="input-group-text"><i className="bx bx-dollar"></i></span>
                <input type="text" id="price" className="form-control dt-full-name" name="price" defaultValue={price} onChange={(e)=>setPrice(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">ADD ON PRICE</label>
              <div className="input-group input-group-merge">
                <span id="addonprice" className="input-group-text"><i className="bx bx-dollar"></i></span>
                <input type="text" id="addonprice" className="form-control dt-full-name" name="addonprice" defaultValue={addonprice} onChange={(e)=>setAddOnPrice(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12 d-none">
              <label className="form-label">IMAGE</label>
              <div className="input-group input-group-merge">
                <span id="image" className="input-group-text"><i className="bx bx-image"></i></span>
                <input type="file" id="image" className="form-control dt-full-name" name="image" defaultValue={image} onChange={(e)=>setImage(e.target.value)}/>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">Integration</label>
              <div className="input-group input-group-merge">
                <span id="integration" className="input-group-text"><i className="bx bxl-shopify"></i></span>
                <select defaultValue={integration} name="integration" id="integration" className="form-control" onChange={(e)=>setIntegration(e.target.value)}>
                    <option value="shopify">Shopify</option>
                    <option value="magento">Magento</option>
                    <option value="woocommerce">Woocommerce</option>
                    <option value="salesforce">SaleForce</option>
                </select>
              </div>
            </div>
            <div className="col-sm-12">
              <label className="form-label">STATUS</label>
              <div className="form-check form-switch mb-2">
                <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" defaultChecked={isEnabled} onChange={(e)=>setIsEnabled(e.target.checked)}/>
                <label className="form-check-label"></label>
              </div>
            </div>
            <div className="col-sm-12">
              {!isOnProcess ? 
              <button type="submit" className="btn btn-primary data-submit me-sm-3 me-1" onClick={handleSubmit}>Submit</button>:
              <button type="button" className="btn btn-primary data-submit me-sm-3 me-1" disabled>Saving...</button>
              } 
              <button type="reset" className="btn btn-outline-secondary" data-bs-dismiss="offcanvas">Cancel</button>
            </div>
          </form>

        </div>
      </div>
    </div>:
    <div></div>
  )
}

export default License